import React, { useState, useEffect } from "react";
import { MenuList } from "../helpers/MenuList";
import Fade from "react-reveal/Fade";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="menu">
      <div className="menuTitle">Our Services</div>
      <Fade bottom cascade>
        <div className="menuList">
          {MenuList.map((menuItem, key) => {
            return (
              <div className="menuItem" key={key}>
                <div className="image-data">
                  <ul className="hover-items">
                    <li>
                      <>
                        <VisibilityRoundedIcon
                          style={{ marginRight: "0.5em" }}
                        />
                        View
                      </>
                    </li>
                  </ul>
                </div>
                <div
                  className="menu_img_cont"
                  style={{
                    backgroundImage: `url(${menuItem.image})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
                <div className="menu_text_cont">{menuItem.name}</div>
              </div>
            );
          })}
        </div>
      </Fade>
    </div>
  );
}

export default Services;
