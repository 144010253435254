import React from "react";
import { Link } from "react-router-dom";
import { HashLink as HashLink } from "react-router-hash-link";

function Sidenav({ content,navClick }) {
  return (
    <div id="mySidenav" className={content}>
      <Link to="/" onClick={navClick}> Home </Link>
      <HashLink to="/#services" onClick={navClick} smooth={true}>
        Services
      </HashLink>
      <Link to="/about" onClick={navClick}> About </Link>
      <Link to="/contact" onClick={navClick}> Contact </Link>
    </div>
  );
}

export default Sidenav;
