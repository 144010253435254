import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

function Faq() {
  const [questions, setQuestions] = useState([
    {
      title: "What is the structure of your dedicated team?",
      content:
        "The stucture of the team depends on the project needs and the available inhouse talent.",
      flag: false,
    },
    {
      title: "Can you follow my delivery process?",
      content:
        "We follow the agile methodology to deliver maximum results in the shortest time frame, while prioritizing business metrics with the biggest value. Although we encourage customers to use our approach, the team at MyProfileScore.com can adopt existing workflows and processes.",
      flag: false,
    },
    {
      title: "How do you handle time zone differences?",
      content:
        "Our developers are based out of India and we can arrange calls as per your convenience to discuss the project needs.",
      flag: false,
    },
  ]);

  const open_section = (i) => {
    let qns = [];
    for (let x = 0; x < questions.length; x++) {
      qns.push(questions[x]);
      if (x === i) {
        qns[i].flag == true ? (qns[i].flag = false) : (qns[i].flag = true);
      }
    }

    setQuestions(qns);
    console.log(qns);
  };

  return (
    <div className="FAQ">
      <div className="faq_cont">
        {questions.map((item, i) => (
          <div className="faq_item" key={i}>
            <div className="title" onClick={() => open_section(i)}>
              {!item.flag && <AddCircleOutlineIcon className="icon" />}

              {item.flag && <RemoveCircleOutlineIcon className="icon" />}
              {item.title}
            </div>
            {
              <div style={{ display: !item.flag && "none" }} className="content">
                {item.content}
              </div>
            }
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;
