import React, { useState, useEffect } from "react";

function Terms_of_Use() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacy">
      <div className="content">
        <h1 style={{ fontSize: "2.5em" }}>
          Welcome to our
          <span style={{ color: "#fe5000" }}> Terms of Use</span>
        </h1>
        <h1>Your privacy is critically important to us.</h1>
        <p>
          It is MyProfileScore.com's policy to respect your privacy regarding any
          information we may collect while operating our website. This Privacy
          Policy applies to https://MyProfileScore.com (hereinafter, "us", "we", or
          "https://MyProfileScore.com"). We respect your privacy and are committed to
          protecting personally identifiable information you may provide us
          through the Website. We have adopted this privacy policy ("Privacy
          Policy") to explain what information may be collected on our Website,
          how we use this information, and under what circumstances we may
          disclose the information to third parties. This Privacy Policy applies
          only to information we collect through the Website and does not apply
          to our collection of information from other sources.
        </p>
        <p>
          It is MyProfileScore.com's policy to respect your privacy regarding any
          information we may collect while operating our website. This Privacy
          Policy applies to https://MyProfileScore.com (hereinafter, "us", "we", or
          "https://MyProfileScore.com"). We respect your privacy and are committed to
          protecting personally identifiable information you may provide us
          through the Website. We have adopted this privacy policy ("Privacy
          Policy") to explain what information may be collected on our Website,
          how we use this information, and under what circumstances we may
          disclose the information to third parties. This Privacy Policy applies
          only to information we collect through the Website and does not apply
          to our collection of information from other sources.
        </p>

        <h1>Website Visitors</h1>
        <p>
          Like most website operators, MyProfileScore.com collects
          non-personally-identifying information of the sort that web browsers
          and servers typically make available, such as the browser type,
          language preference, referring site, and the date and time of each
          visitor request. MyProfileScore.com's purpose in collecting non-personally
          identifying information is to better understand how MyProfileScore.com's
          visitors use its website. From time to time, MyProfileScore.com may release
          non-personally-identifying information in the aggregate, e.g., by
          publishing a report on trends in the usage of its website.
        </p>

        <h1>Gathering of Personally-Identifying Information</h1>
        <p>
          Certain visitors to MyProfileScore.com's websites choose to interact with
          MyProfileScore.com in ways that require MyProfileScore.com to gather
          personally-identifying information. The amount and type of information
          that MyProfileScore.com gathers depends on the nature of the interaction. For
          example, we ask visitors who filled the form at https://MyProfileScore.com to
          provide a phone, name and email address.
        </p>

        <h1>Security</h1>
        <p>
          The security of your Personal Information is important to us, but
          remember that no method of transmission over the Internet, or method
          of electronic storage is 100% secure. While we strive to use
          commercially acceptable means to protect your Personal Information, we
          cannot guarantee its absolute security.
        </p>

        <h1>Links To External Sites</h1>
        <p>
          Our Service may contain links to external sites that are not operated
          by us. If you click on a third party link, you will be directed to
          that third party's site. We strongly advise you to review the Privacy
          Policy and terms and conditions of every site you visit. We have no
          control over, and assume no responsibility for the content, privacy
          policies or practices of any third party sites, products or services.
        </p>

        <h1>Aggregated Statistics</h1>
        <p>
          MyProfileScore.com may collect statistics about the behavior of visitors to its
          website. MyProfileScore.com may display this information publicly or provide it
          to others. However, MyProfileScore.com does not disclose your
          personally-identifying information.
        </p>

        <h1>Cookies</h1>
        <p>
          To enrich and perfect your online experience, MyProfileScore.com uses "Cookies",
          similar technologies and services provided by others to display
          personalized content, appropriate advertising and store your
          preferences on your computer.
        </p>
        <p>
          A cookie is a string of information that a website stores on a
          visitor's computer, and that the visitor's browser provides to the
          website each time the visitor returns. MyProfileScore.com uses cookies to help
          MyProfileScore.com identify and track visitors, their usage of
          https://MyProfileScore.com, and their website access preferences. MyProfileScore.com
          visitors who do not wish to have cookies placed on their computers
          should set their browsers to refuse cookies before using MyProfileScore.com's
          websites, with the drawback that certain features of MyProfileScore.com's
          websites may not function properly without the aid of cookies.
        </p>
        <p>
          By continuing to navigate our website without changing your cookie
          settings, you hereby acknowledge and agree to MyProfileScore.com's use of
          cookies.
        </p>

        <h1>Privacy Policy Changes</h1>
        <p>
          Although most changes are likely to be minor, MyProfileScore.com may change its
          Privacy Policy from time to time, and in MyProfileScore.com's sole discretion.
          MyProfileScore.com encourages visitors to frequently check this page for any
          changes to its Privacy Policy. Your continued use of this site after
          any change in this Privacy Policy will constitute your acceptance of
          such change.
        </p>
      </div>
    </div>
  );
}

export default Terms_of_Use;
