import React from "react";
import { MenuList } from "../helpers/MenuList";
import Fade from "react-reveal/Fade";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import cloud from "../assets/cloud.jpg";
import docker_bg from "../assets/dockerbg.jpg";
import data_analytics from "../assets/data_analytics.jpg";
import web_dev from "../assets/web_dev.jpg";
import ui_ux from "../assets/ui_ux.jpg";
import responsive from "../assets/responsive.jpg";

{
  /*  */
}
var imgs = {
  cloud_services: cloud,
  docker_k8s: docker_bg,
  data_lakes: data_analytics,
};

// https://alpacked.io/services/devsecops-as-a-service/

// cloud and infra security
// container security
// netowrk and firewall security

function Web_dev() {
  let { id } = useParams();
  console.log(id);
  return (
    <div className="service">
      <div
        className="parallax"
        style={{
          backgroundImage: `url(${web_dev})`,
        }}
      >
        <Fade left cascade duration={600}>
          <div className="title_cont">
            <div className="service_page_title">Web Development</div>
            <div className="service_page_content">
              Tell us your vision for your website and we can turn it into
              beautiful web designs and build it for you. We take end to end
              ownership with Hosting, Load testing, SEO and Security included.
            </div>
          </div>
        </Fade>
      </div>
      <div className="box_cont">
        <div className="box box_odd">
          <div className="box_right">
            <div className="box_title">UI/UX Design</div>
            <ul className="box_content">
              <li>Beautiful and eye catching designs.</li>
              <li>Focus on your business objectives.</li>
              <li>Tailored to your target audience.</li>
            </ul>
          </div>
          <div className="box_left">
            <Fade right>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${ui_ux})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
        </div>

        <div className="box box_even">
          <div className="box_left">
            <Fade left>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${responsive})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
          <div className="box_right">
            <div className="box_title">Coding and Development</div>
            <ul className="box_content">
              <li>We make websites responsive to all devices.</li>
              <li>
                Just give us your domain and we will host your website for you.
              </li>
              <li>
                We write optimized code to improve the performance of the
                website.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Web_dev;
