import React, { useState } from "react";

// import { Link as Link_2 } from "react-scroll";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { HashLink as HashLink } from "react-router-hash-link";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

function Navbar() {
  const [openLinks, setOpenLinks] = useState(false);

  const toggleNavbar = () => {
    setOpenLinks(!openLinks);
  };
  return (
    <div className="navbar">
      <div className="leftSide" id={openLinks ? "open" : "close"}>
        <div className="title h-sub-text" id="l2">
          MyProfileScore
          <div className="title_com">.com</div>
        </div>
        {/* <p className="title h-sub-text">.com</p> */}
        {/* <div className="hiddenLinks">
          <Link to="/"> Home </Link>
          <Link to="services" spy={true} smooth={true}>
            Services
          </Link>
          <Link to="/about"> About </Link>
          <Link to="/contact"> Contact </Link>
        </div> */}
      </div>

      <Fade bottom cascade>
        <div className="center">
          <Link to="/"> Home </Link>
          <div className="dropdown">
            <HashLink to="/#services" smooth={true}>
              <div>Services</div>
              <KeyboardArrowDownIcon className="services_icon" />
            </HashLink>
            <div className="dropdown-content">
              <a href="/services/cloud_services">Cloud Services </a>
              <a href="/services/DaaS">Devops as a Service (DaaS)</a>
              <a href="/services/k8s">Kubernetes</a>
              <a href="/services/data_lakes">Data Platform & Analytics</a>
              <a href="/services/web_dev">Web Development </a>
              
            </div>
          </div>

          <Link to="/about"> About </Link>
          <Link to="/contact"> Contact </Link>
          {/* <button onClick={toggleNavbar}>
              <ReorderIcon />
            </button> */}
        </div>
      </Fade>

      {/* <Fade right cascade>
        <div className="rightSide">
          <Link to="/contact">
            <div className="contact_btn">Let's Talk</div>
          </Link>
        </div>
      </Fade> */}
    </div>
  );
}

export default Navbar;
