import React from "react";
import Fade from "react-reveal/Fade";
import { useParams } from "react-router-dom";

import cloud from "../assets/cloud.jpg";
import docker_bg from "../assets/dockerbg.jpg";
import data_analytics from "../assets/data_analytics.jpg";

import cicd1 from "../assets/cicd1.jpg";
import daas from "../assets/daas.jpg";
import iaas from "../assets/iaas.jpg";
import log_management from "../assets/log_management.jpg";
import security_cloud from "../assets/security_cloud.jpg";

import docker_security from "../assets/docker_security.jpg";
var imgs = {
  cloud_services: cloud,
  docker_k8s: docker_bg,
  data_lakes: data_analytics,
};

//from services

function Transforming_ci_cd() {
  let { id } = useParams();
  console.log(id);
  return (
    <div className="service">
      <div
        className="parallax"
        style={{
          backgroundImage: `url(${daas})`,
        }}
      >
        <Fade left cascade duration={600}>
          <div className="title_cont">
            <div className="service_page_title">Devops as a Service (DaaS)</div>
            <div className="service_page_content">
              DaaS covers ample range of DevOps services which helps around
              tooling up to full automation by analyzing the requirements,
              choosing the right tech stack and utimately achieving stramlined
              delivery and deployments.
            </div>
          </div>
        </Fade>
      </div>

      <div className="box_cont">
        <div className="box box_odd">
          <div className="box_right">
            <div className="box_title">
              Continuous integration and deployment (CI/CD)
            </div>
            <ul className="box_content">
              <li>
                CI/CD design from scratch, Tooling definition and selection,
                CI/CD strategy design and roadmapping, End-to-end implementation
              </li>
              <li>
                Current infrastructure evaluation, elimination of bottlenecks,
                Tech stack selection, CI/CD implementation and Maintenance and
                optimization
              </li>
              <li>
                CI/CD pipelines audit & Consulting and control of the execution
              </li>
            </ul>
          </div>
          <div className="box_left">
            <Fade right cascade duration={600}>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${cicd1})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
        </div>

        <div className="box box_even">
          <div className="box_left">
            <Fade left cascade duration={600}>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${iaas})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
          <div className="box_right">
            <div className="box_title">Infrastructure as Code</div>
            <ul className="box_content">
              <li>
                Infrastructure as code design from scratch: Tooling definition
                and selection, Infrastructure automation strategy design and
                roadmapping, End-to-end implementation
              </li>
              <li>
                Infrastructure as code implementation service: Current
                infrastructure evaluation, elimination of bottleneck, Tech stack
                selection, Infrastructure as code implementation
              </li>
            </ul>
          </div>
        </div>

        <div className="box box_odd">
          <div className="box_right">
            <div className="box_title">Log Management and Monitoring</div>
            <ul className="box_content">
              <li>
                End to End Implement of log pipelines using modern technologies.
              </li>
              <li>
                ELK, Prometheus+Grafana - Open-source tools for monitoring,
                alerting, and reporting of security events
              </li>
            </ul>
          </div>
          <div className="box_left">
            <Fade right cascade duration={600}>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${log_management})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
        </div>

        <div className="box box_even">
          <div className="box_left">
            <Fade left cascade duration={600}>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${docker_security})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
          <div className="box_right">
            <div className="box_title">Container Security</div>

            <ul className="box_content">
              <li>
                Rootless mode - Do not allow docker daemon to gain root access
              </li>
              <li>
                Service Mesh - use Istio, Linkerd, AppMesh and other service
                mesh providers for automatic encryption in-transit, control over
                the network connections, JWT authorisation, and other features
              </li>
              <li>
                Image optimization - Follow the security best practices -
                non-root users, multistage builds, read-only binary files
              </li>
            </ul>
          </div>
        </div>

        <div className="box box_odd">
          <div className="box_right">
            <div className="box_title">Cloud and Infra Security</div>

            <ul className="box_content">
              <li>AWS, Azure and GCP security</li>
              <li>
                Stand against brute force attacks, DDoS, code injections, and
                other attacks
              </li>
            </ul>
          </div>
          <div className="box_left">
            <Fade right cascade duration={600}>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${security_cloud})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transforming_ci_cd;
