import React from "react";
import Fade from "react-reveal/Fade";
import { useParams } from "react-router-dom";

import cloud from "../assets/cloud.jpg";
import docker_bg from "../assets/dockerbg.jpg";
import data_analytics from "../assets/data_analytics.jpg";

import data_warehouse from "../assets/data_warehouse.jpg";
import data_lakes from "../assets/data_lakes.jpg";

{
  /*  */
}
var imgs = {
  cloud_services: cloud,
  docker_k8s: docker_bg,
  data_lakes: data_analytics,
};

function Data_lakes() {
  let { id } = useParams();
  console.log(id);
  return (
    <div className="service">
      <div
        className="parallax"
        style={{
          backgroundImage: `url(${data_analytics})`,
        }}
      >
        <div className="title_cont">
          <div className="service_page_title">Data Platform & Analytics</div>
          <div className="service_page_content">
            We provide end-to-end Data & Analytics services to help our
            customers leverage the power of Data and unlock actionable business
            insights.
          </div>
        </div>
      </div>

      <div className="box_cont">
        <div className="box box_odd">
          <div className="box_right">
            <div className="box_title">Data lakes</div>
            <ul className="box_content">
              <li>Snowflakes design and implementation</li>
              <li>AWS glue sage maker and airflow</li>
            </ul>
          </div>
          <div className="box_left">
            <Fade right cascade duration={600}>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${data_lakes})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
        </div>

        <div className="box box_even">
          <div className="box_left">
            <Fade left cascade duration={600}>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${data_warehouse})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
          <div className="box_right">
            <div className="box_title">Enterprise data warehouse solution</div>
            <ul className="box_content">
              <li>Data Warehouse Design. architecture and development</li>
              <li>
                Integrate structured and unstructured data from online and
                offline systems and add new data feeds quickly and easily
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Data_lakes;
