import React from "react";
import Fade from "react-reveal/Fade";
import { useParams } from "react-router-dom";

import cloud from "../assets/cloud.jpg";
import docker_bg from "../assets/dockerbg.jpg";
import data_analytics from "../assets/data_analytics.jpg";

import containerization_services from "../assets/containerization_services.jpg";
import container_orchestration from "../assets/container_orchestration.jpg";
import audit_consulting from "../assets/audit_consulting.jpg";

// https://alpacked.io/services/containers-orchestration/
var imgs = {
  cloud_services: cloud,
  docker_k8s: docker_bg,
  data_lakes: data_analytics,
};

function Docker_k8s() {
  let { id } = useParams();
  console.log(id);
  return (
    <div className="service">
      <div
        className="parallax"
        style={{
          backgroundImage: `url(${docker_bg})`,
        }}
      >
        <Fade left cascade duration={300}>
          <div className="title_cont">
            <div className="service_page_title">Kubernetes</div>
            <div className="service_page_content">
              Modernize your legacy application program and take advantage of
              containers with our containerization consulting services.
            </div>
          </div>
        </Fade>
      </div>

      <div className="box_cont">
        <div className="box box_odd">
          <div className="box_right">
            <div className="box_title">Containerization Services</div>
            <ul className="box_content">
              <li>Container Orchestration in cloud</li>
              <li>Container Orchestration on-prem</li>
              <li>Container Management Services (AWS, GCP, Azure)</li>
            </ul>
          </div>
          <div className="box_left">
            <Fade right>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${containerization_services})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
        </div>

        <div className="box box_even">
          <div className="box_left">
            <Fade left>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${container_orchestration})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
          <div className="box_right">
            <div className="box_title">Container Orchestration</div>
            <ul className="box_content">
              <li>
                Current infrastructure evaluation, elimination of bottlenecks
              </li>
              <li>Tech stack selection</li>
              <li>Containerization (Docker, Kubernetes)</li>
              <li>Maintenance and optimization</li>
            </ul>
          </div>
        </div>

        <div className="box box_odd">
          <div className="box_right">
            <div className="box_title">Audit, Consulting and Optimization</div>
            <ul className="box_content">
              <li>Infrastructure health audit</li>
              <li>Kubernetes consulting</li>
            </ul>
          </div>
          <div className="box_left">
            <Fade right>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${audit_consulting})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Docker_k8s;
