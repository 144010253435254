import React, { useState, useEffect } from "react";

import about_1 from "../assets/about_1.jpg";
function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="about">
      <div className="aboutTop" style={{ backgroundImage: `url(${about_1})` }}>
        <div className="abt"></div>
      </div>
      <div className="aboutBottom">
          <div className="click_me">
            <div>
              Have any doubts about how we operate? Please click on the below
              link to read about it.
            </div>
          </div>
        <a href="/FAQ">
          <div className="faq blink">FAQs</div>
        </a>
      </div>
    </div>
  );
}

export default About;
