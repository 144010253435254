import React, { useState, useEffect } from "react";

import contact from "../assets/contact.jpg";
import Fade from "react-reveal/Fade";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  toast.configure();

  function sendEmail(e) {
    e.preventDefault();
    console.log(e.target.message);

    emailjs
      .sendForm(
        "service_b6kt2io",
        "template_6mx0mkx",
        e.target,
        "eN3qKSTqFzio20Ibz"
      )
      .then(
        (result) => {
          toast.success("We will get back to you soon !!!", {
            autoClose: 3000,
          });
          console.log(result.text, "heelo");
        },
        (error) => {
          toast.error("Oops!! theres seems to be an error...", {
            autoClose: 3000,
          });
          console.log(error.text);
        }
      );

    e.target.reset();
  }

  return (
    <div className="contact">
      <div
        className="leftSide"
        style={{
          backgroundImage: `url(${contact})`,
          backgroundSize: "contain",
        }}
      ></div>
      <div className="rightSide">
        <Fade duration={1000}>
          <h2> Contact Us</h2>

          <form id="contact-form" onSubmit={sendEmail}>
            <div className="name_cont">
              <div className="fn">
                <label htmlFor="firstname">First Name</label>
                <input
                  name="firstname"
                  placeholder="Enter first name..."
                  type="text"
                  required
                />
              </div>

              <div className="ln">
                <label htmlFor="lastname">Last Name</label>
                <input
                  name="lastname"
                  placeholder="Enter last name..."
                  type="text"
                />
              </div>
            </div>

            <div className="name_cont">
              <div className="ln">
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  placeholder="Enter email..."
                  type="email"
                  required
                />
              </div>

              <div className="ln">
                <label htmlFor="phone">Phone</label>
                <input
                  name="phone"
                  placeholder="Enter phone no..."
                  type="text"
                  required
                />
              </div>
            </div>

            <label htmlFor="interested">Interested in</label>
            <input
              name="interested"
              placeholder="Topic interested in..."
              type="text"
              required
            />
            <label htmlFor="message">Message</label>
            <textarea
              rows="6"
              placeholder="Enter message..."
              name="message"
            ></textarea>
            <button type="submit"> Send Message</button>
          </form>
        </Fade>
      </div>
    </div>
  );
}

export default Contact;
