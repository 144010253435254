import React from "react";
import Fade from "react-reveal/Fade";
import { useParams } from "react-router-dom";

import cloud from "../assets/cloud.jpg";
import docker_bg from "../assets/dockerbg.jpg";
import data_analytics from "../assets/data_analytics.jpg";

import cloud_migration from "../assets/cloud_migration.jpg";
import cloud_consulting from "../assets/cloud_consulting.jpg";
import cloud_arch_as_design from "../assets/cloud_arch_as_design.jpg";

{
  /*  */
}
var imgs = {
  cloud_services: cloud,
  docker_k8s: docker_bg,
  data_lakes: data_analytics,
};

function Cloud_services() {
  let { id } = useParams();
  console.log(id);
  return (
    <div className="service">
      <div
        className="parallax"
        style={{
          backgroundImage: `url(${cloud})`,
        }}
      >
        <Fade left cascade duration={600}>
          <div className="title_cont">
            <div className="service_page_title">Cloud Services</div>
            <div className="service_page_content">
              We really believe in well architected infrastructure in cloud. And
              the key factors that drive us are : Security, Reliability,
              Performance optimization, Cost effectiveness.
            </div>
          </div>
        </Fade>
      </div>
      <div className="box_cont">
        <div className="box box_odd">
          <div className="box_right">
            <div className="box_title">Cloud Migration</div>
            <ul className="box_content">
              <li>Cloud Migration business case preparation</li>
              <li>On-Prem vs Cloud Operating model mapping</li>
              <li>Migration security & compliance</li>
            </ul>
          </div>
          <div className="box_left">
            <Fade right duration={600}>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${cloud_migration})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
        </div>

        <div className="box box_even">
          <div className="box_left">
            <Fade left duration={600}>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${cloud_consulting})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
          <div className="box_right">
            <div className="box_title">Cloud Strategy & Consulting</div>
            <ul className="box_content">
              <li>Existing Cloud Infrastructure audit</li>
              <li>AWS, Azure, GCP consulting</li>
              <li>Identify Cloud platform & DevOps tools</li>
            </ul>
          </div>
        </div>

        <div className="box box_odd">
          <div className="box_right">
            <div className="box_title">Cloud Architecture Design</div>
            <ul className="box_content">
              <li>Application architecture</li>
              <li>Network design, Security design and Scaling</li>
              <li>Initial system sizing and storage design</li>
              <li>Monitoring and alerting</li>
              <li>Cloud architecture consulting</li>
            </ul>
          </div>
          <div className="box_left">
            <Fade right duration={600}>
              <div
                className="box_img"
                style={{
                  backgroundImage: `url(${cloud_arch_as_design})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cloud_services;
