import "./App.scss";
import Navbar from "./components/Navbar";
import Sidenav from "./components/Sidenav";

import Footer from "./components/Footer";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Service from "./pages/Services";
import Transforming_ci_cd from "./pages/Transforming_ci_cd";
import Faq from "./pages/Faq";
import Terms_of_Use from "./pages/Terms_of_Use";

import Web_dev from "./pages/Web_dev";
import Docker_k8s from "./pages/Docker_k8s";
import Data_lakes from "./pages/Data_lakes";
import Cloud_services from "./pages/Cloud_services";

import About from "./pages/About";
import Contact from "./pages/Contact";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";

function App() {
  const [navToggle, setNavToggle] = useState(true);

  const navClick = () => {
    setNavToggle(!navToggle);
  };

  return (
    <div className="App">
      <Router>
        <Navbar />
        <Sidenav
          content={`sidebar ${navToggle ? "nav-toggle" : ""}`}
          navClick={navClick}
        />

        {navToggle ? (
          <span
            style={{
              fontSize: "30px",
              cursor: "pointer",
            }}
            onClick={navClick}
            className="hamburger"
          >
            &#9776;
          </span>
        ) : (
          <span
            style={{
              fontSize: "30px",
              cursor: "pointer",
            }}
            onClick={navClick}
            className="cross"
          >
            &times;
          </span>
        )}

        <Switch>
          <Route path="/" exact component={Home} />
          {/* <Route path="/menu" exact component={Services} /> */}
          <Route path="/about" exact component={About} />
          <Route path="/Services/cloud_services" component={Cloud_services} />
          <Route path="/Services/DaaS" component={Transforming_ci_cd} />
          <Route path="/Services/web_dev" component={Web_dev} />
          <Route path="/Services/k8s" component={Docker_k8s} />
          <Route path="/Services/data_lakes" component={Data_lakes} />

          <Route path="/contact" exact component={Contact} />
          <Route path="/FAQ" exact component={Faq} />
          <Route path="/Terms_of_Use" exact component={Terms_of_Use} />


        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
