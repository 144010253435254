import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import bg from "../assets/bg.jpg";
import cicd from "../assets/cicd.jpg";
import docker from "../assets/docker.jpg";
import analytics from "../assets/analytics.jpg";
import web_dev_icon from "../assets/web_dev_icon.jpg";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CodeIcon from "@mui/icons-material/Code";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="homepage">
      <div
        className="home"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="home_left">
          <Fade>
            <div className="About_us">Result-Oriented Dedicated Team</div>
            Need a new piece of software delivered, deployed and tested as fast
            as possible?
            <br />
            <br />
            Partner with My Profile Score to get tangible results in production
            within just 4-6 weeks and remove hiring complexity and
            administrative headache.
          </Fade>
        </div>
      </div>

      <Fade>
        <div id="services">
          <div className="sr2">
            <div className="c123">
              <div className="services_title">Our Services</div>
              <div className="services_cont">
                We help Internet-based businesses and product companies design
                and develop cloud-native web and mobile solutions.
              </div>
            </div>

            <a href="/services/cloud_services" className="c1">
              <div className="first_half">
                <CloudQueueIcon className="icon"></CloudQueueIcon>
              </div>
              <div className="second_half">
                <div className="cat_title">Cloud Services</div>
                <div className="cat_content">
                  Leveraging our deep expertise in Cloud & DevOps to help
                  customers define, execute and manage their Cloud journey.
                </div>
              </div>
            </a>

            <a href="/services/DaaS" className="c1">
              <div className="first_half">
                <img src={cicd} alt="" className="daas" />
              </div>
              <div className="second_half">
                <div className="cat_title">DevOps as a Service</div>
                <div className="cat_content">
                  DaaS helps around tooling up to full automation by analyzing
                  requirements, choosing the right tech stack and utimately
                  achieving stramlined delivery and deployments.
                </div>
              </div>
            </a>
          </div>

          <div className="sr2">
            <a href="/services/k8s" className="c1">
              <div className="first_half">
                <img src={docker} alt="" className="docker" />
              </div>
              <div className="second_half">
                <div className="cat_title">Kubernetes </div>
                <div className="cat_content">
                  Modernize your legacy application program and take advantage
                  of containers with our containerization consulting services.
                </div>
              </div>
            </a>

            <a href="/services/data_lakes" className="c1">
              <div className="first_half">
                <img src={analytics} alt="" className="analytics" />
              </div>
              <div className="second_half">
                <div className="cat_title">Data Platform & Analytics</div>
                <div className="cat_content">
                  We provide end-to-end Data & Analytics services to help our
                  customers leverage the power of Data and unlock actionable
                  business insights.
                </div>
              </div>
            </a>

            <a href="/services/Web_dev" className="c1">
              <div className="first_half">
                <img src={web_dev_icon} alt="" className="web_dev" />
              </div>
              <div className="second_half">
                <div className="cat_title">Web Development</div>
                <div className="cat_content">
                  Tell us your ideas for your website and we can turn them into
                  beautiful web designs and build it for you. We take E2E
                  ownership with hosting, load testing, SEO and security
                  included.
                </div>
              </div>
            </a>
          </div>

          <div className="tangible_results">
            <div className="title">Why choose MyProfileScore.com?</div>
            <div className="results_cont">
              <div className="box1">
                <div className="first">
                  <AccessAlarmIcon className="icon"></AccessAlarmIcon>
                </div>
                <div className="second">
                  <div className="box_title">
                    Reduce time of hiring and onboarding
                  </div>
                  <div className="content">
                    No need to spend time on screening, intervieweing and
                    adpting engineers. Get staright to work with a well quipped
                    team ready to kick-off.
                  </div>
                </div>
              </div>
              <div className="box1">
                <div className="first">
                  <RocketLaunchIcon className="icon"></RocketLaunchIcon>
                </div>
                <div className="second">
                  <div className="box_title">Get visible results faster</div>
                  <div className="content">
                    First working code within 4-6 weeks. Sprint based demos
                    every 2 weeks.
                  </div>
                </div>
              </div>
              <div className="box1">
                <div className="first">
                  <AttachMoneyIcon className="icon"></AttachMoneyIcon>
                </div>
                <div className="second">
                  <div className="box_title">Pay for results not efforts</div>
                  <div className="content">
                    We offer a performance based compensation model. Focus on
                    improving business KPIs in production.
                  </div>
                </div>
              </div>
              <div className="box1">
                <div className="first">
                  <CodeIcon className="icon"></CodeIcon>
                </div>
                <div className="second">
                  <div className="box_title">Eliminate code waste</div>
                  <div className="content">
                    We just don't write code. We measure it's performance in
                    prduction and optimize to reach business targets.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="explore">
            <div className="left">Are you ready to explore with us?</div>
            <div className="right">
              <Link to="/contact">
                <div className="button">Let's Talk</div>
              </Link>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Home;
