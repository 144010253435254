import React from "react";


function Footer() {
  {
    /* &copy; */
  }
  return (
    <div className="footer">
      <div className="row_cont">
        <div className="row1">
          <div className="c1">
            <div className="logo">
              <div> MyProfileScore</div>
              <div className="com">.com</div>
            </div>
          </div>
          <div className="c2">
            <div className="title">Services</div>
            <div className="content">
              <div className="cont-item">
                Continuous integration and deployment
              </div>
              <div className="cont-item">DevOps outsourcing</div>
              <div className="cont-item">Serverless architecture design</div>
              <div className="cont-item">Cloud architechture design</div>
              <div className="cont-item">Log Management and Monitoring</div>
              <div className="cont-item">Infrastructure as Code</div>
              <div className="cont-item">DevOps Consulting services</div>
              <div className="cont-item">Cloud migration services</div>
              <div className="cont-item">DevOps as a service</div>
              <div className="cont-item">Container orchestration services</div>
              <div className="cont-item">Web Development</div>
            </div>
          </div>
        </div>

        <div className="row2">
          <div className="c2 c4">
            <div className="title">Consulting</div>
            <div className="content">
              <div className="cont-item">Serverless Consulting</div>
              <div className="cont-item">Cloud Consulting</div>
              <div className="cont-item">Infrastructure as code Consulting</div>
              <div className="cont-item">Microservices Consulting</div>
              <div className="cont-item">Kubernetes Consulting</div>
              <div className="cont-item">Security Consulting</div>
              <div className="cont-item">CI/CD Consulting</div>
              <div className="cont-item">DevOps Consulting</div>
              <div className="cont-item">AWS Consulting</div>
            </div>
          </div>
          <div className="c2 ">
            <div className="title">Technologies</div>
            <div className="content">
              <div className="cont-item">AWS</div>
              <div className="cont-item">Azure</div>
              <div className="cont-item">GCP</div>
              <div className="cont-item">Kubernetes</div>
              <div className="cont-item">Airflow</div>
              <div className="cont-item">Snowflake</div>
              <div className="cont-item">Terraform</div>
              <div className="cont-item">CI/CD</div>
              <div className="cont-item">ELK Stack</div>
              <div className="cont-item">DevSecOps</div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright">
        <div>&copy; MyProfileScore.com LLC</div>
        <div className="footer_bottom_right">
          <div className="footer_btm_item">
            <a href="/Terms_of_Use">Terms of Use</a>
          </div>
  
          <div className="footer_btm_item">All rights reserved.</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
